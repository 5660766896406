import React, { Component } from "react";
import GoalCard from "../../GoalCard";
import calendar from "../../../assets/images/calendar.svg";
import clock from "../../../assets/images/clock-circular.svg";
import "../../../assets/css/EmployeeGoalItem.css";
import timeStampToDateTime from "../../../core/lib/TimeStampToDateTime";
const STYLES = {
  imgAndDate: { display: "flex", paddingBottom: 6 },
  dateAndTime: {
    marginLeft: 4,
  },
};
class EmployeeGoalItem extends Component {
  render() {
    return (
      <div
        onClick={this.props.onClickGoal}
        style={{
          minHeight: 138,
          display: "flex",
          fontFamily: "Open Sans Regular",
          boxShadow: "2px 5px 8px #80808057",
          borderRadius: 8,
          zIndex: 3,
          cursor: this.props.cursor ? "pointer" : "default",
          background: "#FFFFFF",
        }}
      >
        <div style={{ flex: 0.12 }}>
          <GoalCard {...this.props} />
        </div>

        <div
          className="descriptionContainer"
          style={{
            flex: 1,
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
              marginLeft: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "16px 16px 16px 0px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "1em",
                  color: "#303030",
                }}
              >
                {this.props.title || this.props.goalTitle}
              </div>
              <div>
                <div
                  style={{
                    padding: "5px 10px",
                    background: "#F4F4FE",
                    fontSize: "0.75em",
                    borderRadius: 4,
                    marginLeft: 14,
                    color: "#7676C1",
                  }}
                >
                  {this.props.type || this.props.goalType}
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "0 10% 6px 0",
                fontSize: "0.875em",
                color: "#828282",
              }}
            >
              {this.props.description || this.props.goalDescription}
            </div>
          </div>
          <div className="timeDateContainer">
            <div className="startDateContainer">
              <div className="dateTitle">Started on</div>
              <div>
                <div style={STYLES.imgAndDate}>
                  <img src={calendar} alt="date" />
                  <div style={STYLES.dateAndTime}>
                    {timeStampToDateTime(this.props.startDate).date}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <img src={clock} alt="date" />
                  <div style={STYLES.dateAndTime}>
                    {timeStampToDateTime(this.props.startDate).time}
                  </div>
                </div>
              </div>
            </div>
            <div className="endingDateContainer">
              <div className="dateTitle">Ending on</div>
              <div>
                <div style={STYLES.imgAndDate}>
                  <img src={calendar} alt="date" />
                  <div style={STYLES.dateAndTime}>
                    {timeStampToDateTime(this.props.endDate).date}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <img src={clock} alt="date" />
                  <div style={STYLES.dateAndTime}>
                    {timeStampToDateTime(this.props.endDate).time}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeGoalItem;
